<template>
    <el-scrollbar class="contact-wrapper" style="height: 100%" :native="false">
        <div class="contact-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>联系我们</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="contact-detail">
                <span>请添加联系人</span>
                <!--<img src="../../assets/images/contact-img.png" alt="">-->
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>

<style scoped lang="scss">
    /*::v-deep .el-breadcrumb {*/
        /*.el-breadcrumb__inner {*/
            /*color: #000;*/
        /*}*/
    /*}*/
    .contact-wrapper {
        width: 100%;
        padding: 20px 0;
        height: calc(100% - 40px);
        overflow: auto;
        .contact-content {
            height: 100%;
            width: 1200px;
            margin: 0 auto;
            .contact-detail {
                padding: 40px 0;
                border-top: 1px solid #EAEAEA;
                margin-top: 20px;
            }
        }
    }
</style>